import "./about-spice.css";

const AboutSpiceComponent = () => {
    return (
        <>
            <div class="content-about">
                <h4>About SPICE</h4>
                <p class="intro">
                    Antimicrobial resistance (AMR) poses a significant threat globally, limiting treatment options and endangering medical interventions. In 2019, AMR was linked to an estimated 4.95 million deaths, predominantly affecting low- and middle-income countries. Governments worldwide have acknowledged the AMR crisis, emphasizing the need for tailored policy solutions, improved infection prevention, antimicrobial stewardship, and better antibiotic use. Effective solutions require changes in antibiotic prescribing and consumption, especially in resource-limited settings where clinical guidance is often inadequate.
                </p>
                <p class="intro">
                Efforts such as the WHO's Global Action Plan on AMR and national action plans have created an enabling environment, but challenges remain at implementation level. To address the above need, in 2022 the SPICE program was launched, aimed at extending efforts to provide needed expertise to resource-limited settings and contexts, fostering sustainable infectious disease management, and reducing dependency on foreign aid. The SPICE initiative also contributes to improvement of antimicrobial use through the data-driven behavioral change-based drive-AMS program, providing mentorship and ensuring best practices are sustainably embedded in local healthcare systems.
                </p>
                <h4>
                The SPICE program addresses this expertise-to-need mismatch through assigning expert support in the following areas: 
                </h4>
                <ul>
                    <li>Infectious disease management</li>
                    <li>Microbiology and diagnostics</li>
                    <li>Infection prevention and control</li>
                    <li>Antimicrobial Stewardship</li>
                    <li>Implementation science and behavior change</li>
                </ul>
                {/* <h4>Governance</h4>
                <h4>Secretariat</h4>
                <h4>Projects</h4>
                <h4>Missions</h4> */}

            </div>
        </>
    )
}

export default AboutSpiceComponent;
import { AppRoutes } from '../../consts/routes/app-routes';
import './partnerships.css';

const PartnershipsComponent = () => {
    return (
        <div class="content-p">
        <p class="intro">
        The SPICE registry is based on support and partnerships with international organisations, government institutions, professional societies and other stakeholders. These partnerships foster bidirectional exchange of experience and expertise intended to assist healthcare settings, governments and countries in implementing specialized infectious disease care and management in a sustainable way.        </p>
        <h1>Partnerships</h1>
        <ul class="partnerships">
            <li>Dutch Programma Uitzending Managers (PUM)</li>
            <li>International Centre for Antimicrobial Resistance Solutions (ICARS)</li>
            <li>the Dutch Public Health Institute (RIVM)</li>
        </ul>
        <h1>Project-based collaborations</h1>
        <ul class="collaborations">
            <li>drive-AMS Project, co-funded by the EU4H Programme</li>
            <li>drive-AMS LMICs Project, co-funded by ICARS</li>
            <li>ESCMID-AMS Certificate programme</li>
            <li>Reinier Post Stichting</li>
        </ul>
        <p>Read more about <a href={AppRoutes.WhereWeWork}>where we work.</a></p>
        <h4>Get Involved</h4>
        <p class="contact">
            If you are interested in partnering with us in the SPICE mission for improved infection care everywhere, please <a href={AppRoutes.ContactUs}>contact us here</a>.
        </p>
    </div>
    )
}

export default PartnershipsComponent;
import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import "./footer.css";
export const Footer = () => (
    <div className="footer-box">
        <div className="footer-container">
            <p style={{ fontWeight: '700', color: '#2B3B85' }}>
                SPICE Global, Initiative of Radboud University Medical Center Nijmegen
                </p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 style={{ fontWeight: '700', color: '#2B3B85' }}>Find us:</h2>
                <a className="footer-link" href="https://www.radboudumc.nl/en/radboudumc-community-for-infectious-diseases/our-expert-areas/specialized-program-for-infectious-disease-care-everywhere" target="_blank"><LanguageIcon sx={{ fontSize: '2.2rem' }} /></a>
                {/* <a className="footer-link" href="mailto:study-information@ru.nl" target="_blank"><EmailIcon sx={{ fontSize: '2.2rem' }} /></a> */}
                <a className="footer-link" href="https://x.com/radboudumc" target="_blank"><XIcon sx={{ fontSize: '2.2rem' }} /></a>
                <a className="footer-link" href="https://www.youtube.com/umcstradboud" target="_blank"><YouTubeIcon sx={{ fontSize: '2.2rem' }} /></a>
                <a className="footer-link" href="https://www.linkedin.com/company/radboudumc" target="_blank"><LinkedInIcon sx={{ fontSize: '2.2rem' }} /></a>
            </div>
            <small style={{color: '#2B3B85', textAlign: 'center', marginTop: '2vh' }} >Copyright 2024 © Developed by <a href="https://distantpoint.org/" target="_blank" style={{color:'#2B3B85', fontWeight: '600'}}>Distant Point</a> </small>
       </div>
    </div>
)
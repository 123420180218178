const GenericLoremIpsum = () => {

    return (
        <>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Pariatur optio illo corporis. Explicabo non labore, sit optio repellendus quia blanditiis laborum commodi ipsum tenetur sapiente quisquam cum dolore inventore similique odit placeat, earum aspernatur repellat quos incidunt illum pariatur? Hic, commodi? Asperiores aliquid quaerat id mollitia aperiam necessitatibus, quisquam ab voluptate harum quibusdam at veniam dolor modi error magni dolores.</p>
            <p>Ex placeat quaerat, molestias dolorem magnam quia laborum quae optio harum illo et fuga, quas sunt cupiditate omnis enim numquam ducimus at eveniet</p>
        </>
    )
}

export default GenericLoremIpsum;
import { Button, Typography } from "@mui/material";
import './landing-page-cta.css';
import { Link as RouterLink } from "react-router-dom";
import { AppRoutes } from "../../consts/routes/app-routes";
import { StyledEngineProvider } from '@mui/material/styles';
import { forwardRef } from "react";

const LandingPageCta = () => {
    const CustomLink = forwardRef((props, ref) => (
        <RouterLink ref={ref} {...props} target="_blank" />
    ));

    return (
        <StyledEngineProvider injectFirst>
            <div id='cta-container'>
                <div id="cta-texts-container">
                    <Typography id="cta-heading" className="cta-texts" variant="h6">
                        <b>
                            Empowering Low-Resource Communities: Customized Support for Sustainable Infectious Disease Management
                        </b>
                    </Typography>
                    <Typography className="cta-texts">The Specialized Programme for Infectious Disease Care
                        Everywhere (SPICE) is a global registry of experts with
                        diverse medical specialties who are knowledgeable, skilled
                        and willing to share their expertise and experience with peers
                        through providing technical advice and skill-transfer for better
                        infectious disease care.</Typography>
                    {/* <Typography mx={'auto'} color="#000">Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem aperiam totam labore. Consectetur deserunt vero unde sequi voluptatem.</Typography> */}
                </div>
                <div id='buttons-container'>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '20%', padding: '25px', borderRadius: '10px' }}> */}
                    {/* <Typography sx={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)'}} textAlign={'center'} color="#fff">
                            Join our network of medical centers in an unique mission of connecting the best healthcare professionals with the best healthcare institutions
                        </Typography> */}
                    <Button component={RouterLink} to={AppRoutes.RequestSupportApply} className="cta-button" >
                        <b>Request support</b>
                    </Button>
                    {/* </div> */}
                    {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '20%', padding: '25px', borderRadius: '10px' }}> */}
                    {/* <Typography sx={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)'}} textAlign={'center'} color="#fff">
                            Join our network of experts in an unique mission of connecting the best healthcare professionals with the best healthcare institutions
                        </Typography> */}
                    <Button component={RouterLink} to={AppRoutes.ExpertApply} className="cta-button" >
                        <b>Become an Expert</b>
                    </Button>
                    {/* </div> */}
                    {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '20%', padding: '25px', borderRadius: '10px' }}> */}
                    {/* <Typography sx={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)'}} textAlign={'center'} color="#fff">
                            Join our network of trainers in an unique mission of connecting the best healthcare professionals with the best healthcare institutions
                        </Typography> */}
                    <Button component={RouterLink} to={AppRoutes.TrainerApply} className="cta-button">
                        <b>Become a Trainer</b>
                    </Button>
                    {/* </div> */}
                </div>
            </div>
        </StyledEngineProvider>
    )
}

export default LandingPageCta;
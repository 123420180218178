import { AppRoutes } from '../../consts/routes/app-routes';
import './become-trainer-terms-and-conditions.css';

const BecomeTrainerTermsAndConditionsComponent = () => {
    return (
        <>
            <div class="content-terms-tr">
                <h4>AMS Trainer - Terms and Conditions</h4>
                <p>Becoming a trainer is based on the ‘see-one-do-one-teach-one approach’, which involves participation in one course, undergoing a structured ToT support program delivered annually by Radboudumc core team and co-leading a course together with an already certified trainer.</p>
                <b>What does the ToT program look like?</b>
                <p>The Train the trainer (ToT) program is an online training of <i>4 webinars of about 2 hours at 4 fixed times per year.</i> These trainings all cover one part of the drive AMS training program and -by attending all four- you will be eligible to co-lead one on-site training together with other accredited trainers at one of our training sites worldwide. Once you have co-led one training, you may become an accredited trainer yourself.</p>
                <p>Below you see the dates and subjects to be discussed. The webinars will be provided by the core team of course leaders e.g. Marlies Hulscher, Jaap ten Oever, Teske Schoffelen, Neda Milevska and Jeroen Schouten (Radboudumc, Nijmegen, the Netherlands) and supported by our Global PPS partners Erika Vlieghe, Ann Versporten and Ines Pauwels (University of Antwerp, Belgium)</p>
                <p>During 2024, the ToT webinar program takes place at following times:</p>
                <ul>
                    <li>Preparations (pre-course online preparation) philosophy/ theory and introduction, 20 May 2024 at 20.00 CET </li>
                    <li>Measurement (supported by Global PPS), 2 Sept 2024 at 20.00 CET</li>
                    <li>Barriers and Interventions, 21 October 2024 at 20.00 CET</li>
                    <li>Post course follow up / SPICE program, 27 January 2025 at 20.00 CET</li>
                </ul>
                <p>For this course invited trainers should have received  access to Brightspace (containing all the learning tools and presentations) and a <i>Train the trainer manual.</i> </p>
                <b>What is in it for you?</b>
                <p>In addition to becoming part of a growing network of experts and trainers, the program offers a range of benefits, namely:</p>
                <ul>
                    <li>Get accreditation as a trainer for  Implementation of Antimicrobial stewardship programs (drive AMS) worldwide. Accreditation is issued by Radboudumc (and different partners such as ESCMID, ICARS, EU4H, WHO-AFRO)</li>
                    <li>Be invited as course leads in an international team of experts providing courses in Europe, Africa, Latin America and Southeast Asia</li>
                    <li>Get travel and accommodation support for courses</li>
                    <li>Become expert for hospital teams (allowing for on-site support visits in participating countries) through our <a href={AppRoutes.WhatIsSpiceExpert}>SPICE program</a></li>
                </ul>
                <p>We hope that you will want to be part of this group of enthusiastic experts in the field of Antimicrobial Stewardship implementation and help us build a global community of experts. If you are interested, <a href={AppRoutes.ContactUs}>contact us</a> for more details.</p>
                <p>If you know of others who are interested to become part of this program, feel free to share the invitation or share their details with us.</p>
            </div>
        </>
    )
}

export default BecomeTrainerTermsAndConditionsComponent;
import { AppRoutes } from '../../consts/routes/app-routes';
import './what-is-ams-trainer.css';

const WhatIsAmsTrainerComponent = () => {
    return (
        <>
            <div class="content-wat">
                {/* <h1>What is SPICE Expert</h1> */}
                <p>AMS trainer network is a vibrant group of experts in antimicrobial stewardship from a number of medical specialties (infectious diseases, microbiology, clinical pharmac(olog)y, intensive care, nursing, etc.) and implementation science and behaviour change specialists.</p>
                <p>Our AMS trainers are equipped with the necessary knowledge and skills to deliver the drive-AMS philisophy, based on a unique approach combining measurement, contextualized learning, development and implemenation of AMS interventions.</p>
                <p>Becoming an expert, offers an opportunity to be part of a growing network of like-minded professionals dedicated to addressing antimicrobial resistance at the local - service delivery level, as one of the key pathways to making a significant impact on the AMR burden globally.</p>
                <h4>What is expected from you?</h4>
                <p>Our trainers are invited to take part in number of running courses to assist with program adaptation, delivery and follow-up. </p>
                <p>Currently we have active programs in Europe, Africa, South-east Asia and Latin America. Find out more on our <a href='https://www.radboudumc.nl/en/education/courses/dutch-antimicrobial-stewardship' target='_blank'>drive-AMS program page.</a></p>
                <p>Interested? <a href={AppRoutes.TermsAndConditionsTrainer}>Read more here.</a></p>
            </div>
        </>
    )
}

export default WhatIsAmsTrainerComponent;
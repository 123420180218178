import GenericLoremIpsum from "../shared/generic-loremipsum"

const OurTrainers = () => {
    return (
        <>
            <div>
                <h4>Our Trainers</h4>
                <GenericLoremIpsum />
            </div>
        </>
    )
}

export default OurTrainers;
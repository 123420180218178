import GenericLoremIpsum from "../shared/generic-loremipsum"

const RequestSupportTermsAndConditionsComponent = () => {
    return (
        <>
            <div>
                <h4>Request Support - Terms and Conditions</h4>
                <GenericLoremIpsum />
            </div>
        </>
    )
}

export default RequestSupportTermsAndConditionsComponent;
import { Delete, UploadFile } from "@mui/icons-material";
import { Alert, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, Slider, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useRef } from "react";
import { expertsApplyFormProps, hospitalsApplyFormProps, requiredExpertsApplyFormProps } from "../../consts/form-helpers/form-consts";
import { expertApply, verifyCaptcha } from "../../services/apply-service";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import './become-expert-apply.css';
import { AppRoutes } from '../../consts/routes/app-routes';

const BecomeExpertApplyComponent = () => {
    const HCAPTCHA_KEY = 'd2169502-dac0-4a8c-b8c0-18b4da968a86';
    const fileInputRef = useRef(null);
    const [selectedFields, setSelectedFields] = useState([]);
    const [acceptConsent, setAcceptConsent] = useState('');
    const [declarationOfHonour, setDeclarationOfHonour] = useState('');
    const [countryOptions, setCountryOptions] = useState([]);
    const [fileResume, setFileResume] = useState(null);
    const [fileEndorsment, setFileEndorsment] = useState(null);
    const [error, setError] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const [emptyFormProps, setEmptyFormProps] = useState([]);
    const [consentError, setConsentError] = useState('');
    const [declarationOfHonourError, setDeclarationOfHonourError] = useState('');
    const [formData, setFormData] = useState({ ...expertsApplyFormProps });
    const [isSuccess, setIsSuccess] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [isSurgeryChecked, setIsSurgeryChecked] = useState(false);
    const [availability, setAvailability] = useState({
        onlineOnly: false,
        onsiteOnly: false,
        onlineOnsite: false,
        other: '',
    });

    const languageMarks = [{ value: 0, label: 'None' }, { value: 1, label: 'Basic' }, { value: 2, label: 'Intermediate' }, { value: 3, label: 'Expert' }, { value: 4, label: 'Native' }];

    const languages = [
        { id: 1, value: 'English' },
        { id: 2, value: 'French' },
        { id: 3, value: 'German' },
        { id: 4, value: 'Russian' },
        { id: 5, value: 'Spanish' },
        { id: 6, value: 'Italian' }
    ];

    const [proficiency, setProficiency] = useState({
        English: 0, French: 0, German: 0, Russian: 0, Spanish: 0, Italian: 0, Other: 0
    });

    const handleSliderChange = (language) => (e, value) => {
        if (language !== 'Other') {
            setProficiency({ ...proficiency, [language]: value });

            const entry = {
                id: languages.find(l => l.value === language).id,
                value: language,
                proficiency: languageMarks.find(x => x.value === value).label
            };

            setFormData({
                ...formData,
                language_proficiency: [...(formData.language_proficiency?.filter(item => item.value !== language) || []), entry]
            });

            if (emptyFormProps.includes('language_proficiency')) {
                setEmptyFormProps((props) => props.filter(prop => prop !== 'language_proficiency'));
            }
        } else {
            setProficiency({ ...proficiency, [language]: value });
        }
    };

    const handleCaptchaVerify = token => {
        setError(null);
        setCaptchaToken(token);
    };

    const handleInputChange = e => {
        setIsOtherSelected(e.target.value === 'Other');

        setError(null);
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (emptyFormProps.includes(e.target.name)) {
            setEmptyFormProps((props) => props.filter(prop => prop !== e.target.name));
        }
    };

    const handleCheckboxChange = (field) => (e) => {
        setAvailability({ onlineOnly: false, onsiteOnly: false, onlineOnsite: false, [field]: e.target.checked });
        setFormData({ ...formData, general_availability: field });
        if (emptyFormProps.includes('general_availability')) {
            setEmptyFormProps((props) => props.filter(prop => prop !== 'general_availability'));
        }
    };

    const handleDeclarationOfHonourChange = e => {
        setDeclarationOfHonour(e.target.value);
        setFormData({ ...formData, declaration_of_honour: e.target.value });

        if (e.target.value !== 'no') {
            setDeclarationOfHonourError('');
        };

        if (emptyFormProps.includes('declaration_of_honour')) {
            setEmptyFormProps((props) => props.filter(prop => prop !== 'declaration_of_honour'));
        }
    };

    const handleAcceptConsent = e => {
        setAcceptConsent(e.target.value);
        setFormData({ ...formData, consent: e.target.value });

        if (e.target.value !== 'no') {
            setConsentError('');
        };

        if (emptyFormProps.includes('consent')) {
            setEmptyFormProps((props) => props.filter(prop => prop !== 'consent'));
        }
    };

    const handleSpecialtyCheckboxChange = (specialty) => {
        if (specialty.value === 'Surgery (please specify subspeciality):') {
            setIsSurgeryChecked(true);
        }

        setFormData({ ...formData, specialty_ids: [...(formData.specialty_ids || []), specialty.id] });

        if (emptyFormProps.includes('specialty_ids')) {
            setEmptyFormProps((props) => props.filter(prop => prop !== 'specialty_ids'));
        }
    }

    const validateForm = () => {
        let isFormValid = true;

        for (let prop of requiredExpertsApplyFormProps) {
            if (Array.isArray(formData[prop])) {
                if (formData[prop].length === 0) {
                    setEmptyFormProps((prev) => [...prev, prop]);
                    setError('Please fill out all required fields');
                    isFormValid = false;
                }
            }
            // else if(prop === 'work_experience' === formData[prop] === 0){
            //     setEmptyFormProps((prev) => [...prev, prop]);
            //     setError('Please fill out all required fields');
            //     isFormValid = false;
            // } 
            else {
                if (formData[prop] === '' || formData[prop] === null || formData[prop] === false) {
                    if (prop === 'declaration_of_honour') {
                        setDeclarationOfHonourError('You need to accept the declaration to proceed.')
                        isFormValid = false;
                    };

                    if (prop === 'consent') {
                        setConsentError('You need to give consent to proceed.')
                        isFormValid = false;
                    };

                    setEmptyFormProps((prev) => [...prev, prop]);
                    setError('Please fill out all required fields');
                    isFormValid = false;
                };

                if (prop === 'consent' && acceptConsent === 'no') {
                    setConsentError('You must give consent to proceed.');
                    setError('Please fill out all required fields');
                    isFormValid = false;
                };

                if (prop === 'declaration_of_honour' && declarationOfHonour === 'no') {
                    setDeclarationOfHonourError('You must accept the terms and conditions to procede.');
                    setError('Please fill out all required fields');
                    isFormValid = false;
                };
            }
        };

        return isFormValid;
    }

    const handleSubmit = async () => {
        if (formData.language_proficiency_other !== '' || formData.language_proficiency_other !== null) {
            formData.language_proficiency_other = `${formData.language_proficiency_other} - ${languageMarks.find(x => x.value === proficiency.Other).label}`;
        }

        if (validateForm()) {
            verifyCaptcha({ token: captchaToken }).then((res) => {
                if (res.success) {
                    expertApply(formData).then((res) => { }).catch(er => console.log(er));
                    setIsSuccess(true);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                    setError('Captcha verification failed. Please try again.');
                }
            })
        }
    }

    const handleFileChange = (e, isResume) => {
        if (isResume) {
            setEmptyFormProps((props) => props.filter(prop => prop !== 'professional_resume_base64'));
            const file = e.target.files[0];
            const fileExtension = file?.name.split('.').pop();
            const reader = new FileReader();
            setFileResume(e.target.files[0]);

            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setFormData({ ...formData, professional_resume_base64: base64String, professional_resume_base64_extension: fileExtension });
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        } else {
            setEmptyFormProps((props) => props.filter(prop => prop !== 'professional_endorsment_base64'));
            const file = e.target.files[0];
            const fileExtension = file?.name.split('.').pop();
            const reader = new FileReader();
            setFileEndorsment(e.target.files[0]);

            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setFormData({ ...formData, professional_endorsment_base64: base64String, professional_endorsment_base64_extension: fileExtension });
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    const handleFileRemove = (isResume) => {
        if (isResume) {
            setFileResume(null);
            setFormData({ ...formData, professional_resume_base64: null })
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } else {
            setFileEndorsment(null);
            setFormData({ ...formData, professional_endorsment_base64: null })
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleRegistryRelatedExperienceChange = (field, years_of_experience) => {
        setFormData(prevState => {
            const existingEntry = prevState.registry_related_experience.find(x => x.value === field.value);

            if (existingEntry && existingEntry.years_of_experience === years_of_experience) {
                return { ...prevState, registry_related_experience: prevState.registry_related_experience.filter(x => x.value !== field.value) };
            }

            if (existingEntry) {
                return {
                    ...prevState, registry_related_experience: prevState.registry_related_experience.map(x =>
                        x.value === field.value ? { ...x, years_of_experience } : x
                    ),
                };
            }

            return {
                ...prevState, registry_related_experience: [...prevState.registry_related_experience,
                {
                    id: field.id,
                    value: field.value,
                    years_of_experience
                }
                ]
            };
        });

        if (emptyFormProps.includes('registry_related_experience')) {
            setEmptyFormProps((props) => props.filter(prop => prop !== 'registry_related_experience'));
        }
    };

    const handleRegionalExperienceChange = (field, years_of_experience) => {
        setFormData(prevState => {
            const existingEntry = prevState.regional_experience.find(x => x.value === field.value);

            if (existingEntry && existingEntry.years_of_experience === years_of_experience) {
                return { ...prevState, regional_experience: prevState.regional_experience.filter(x => x.value !== field.value) };
            }

            if (existingEntry) {
                return {
                    ...prevState, regional_experience: prevState.regional_experience.map(x =>
                        x.value === field.value ? { ...x, years_of_experience } : x
                    ),
                };
            }

            return {
                ...prevState, regional_experience: [...prevState.regional_experience,
                { id: field.id, value: field.value, years_of_experience }
                ]
            };
        });

        if (emptyFormProps.includes('regional_experience')) {
            setEmptyFormProps((props) => props.filter(prop => prop !== 'regional_experience'));
        }
    };

    const handleEmailBlur = () => { setEmailError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ? 'Invalid email format' : '') };

    useEffect(() => {
        setError(null);

        axios.get('https://restcountries.com/v3.1/all')
            .then((response) => {
                const newCountries = response.data.map(country => ({
                    label: country.name.common === 'North Macedonia' ? 'Macedonia' : country.name.common,
                    value: country.name.common === 'North Macedonia' ? 'Macedonia' : country.name.common,
                    flag: country.flags.png
                }));

                newCountries.sort((a, b) => a.label.localeCompare(b.label));

                setCountryOptions(newCountries);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //TODO - CHANGE THE HARDCODED COLLECTIONS BELOW WITH A GET CALL FROM THE DB!

    const level = [{ id: 1, value: 'Specialist / clinical', }, { id: 2, value: 'Academic / non-clinical' }];

    const regionalExperience = [
        {
            id: 1,
            value: 'Africa'
        },
        {
            id: 2,
            value: 'Eastern-Mediterranean region'
        },
        {
            id: 3,
            value: 'Europe'
        },
        {
            id: 4,
            value: 'South-East Asia'
        },
        {
            id: 5,
            value: 'Americas (PAHO)'
        },
        {
            id: 6,
            value: 'Western Pacific'
        }
    ];

    //СТАЈ ИМ OTHER (PLEASE SPECIFY) НА ОВЈА ДОЛНИВЕ!!!!!!!!!!!!!!!!!!!!
    const specificRegistryRelatedExperience = [
        {
            id: 1,
            value: 'Infectious disease management'
        },
        {
            id: 2,
            value: 'Microbiology and diagnostics'
        },
        {
            id: 3,
            value: 'Infection prevention and control'
        },
        {
            id: 4,
            value: 'Antimicrobial Stewardship'
        },
        {
            id: 5,
            value: 'Implementation science and behavior change'
        }
    ]

    const specialtyFields = [
        {
            id: 1,
            value: 'Infectious diseases management'
        },
        {
            id: 2,
            value: 'Clinical microbiology'
        },
        {
            id: 3,
            value: 'Infection control'
        },
        {
            id: 4,
            value: 'Intensive care'
        },
        {
            id: 5,
            value: 'Clinical pharmacy'
        },
        {
            id: 6,
            value: 'Pharmacology'
        },
        {
            id: 7,
            value: 'Nursing'
        },
        {
            id: 8,
            value: 'Pediatrics'
        },
        {
            id: 9,
            value: 'Pulmonology'
        },
        {
            id: 10,
            value: 'Surgery (please specify subspeciality):'
        }, //ОВА ДА СЕ ИСХЕНДЛА
        {
            id: 11,
            value: 'Internal medicine'
        },
        {
            id: 12,
            value: 'Laboratory – microbiology'
        },
        {
            id: 13,
            value: 'Laboratory – pathology'
        },
        {
            id: 14,
            value: 'Public health'
        },
        {
            id: 15,
            value: 'General/family medicine'
        },
        {
            id: 16,
            value: 'Veterinary sciences'
        },
        {
            id: 17,
            value: 'Implementation science'
        },
        {
            id: 18,
            value: 'Social sciences (anthropology, social care, etc.)'
        },
        {
            id: 19,
            value: 'Psychology'
        },
        {
            id: 20,
            value: 'Economics and related sciences'
        }
    ];

    return (
        <>
            <div className="content-bea">
                <Typography variant="h6" color={'primary'} m={2.5} textAlign={'center'}>
                    The Specialized Programme for Infection Care Everywhere (SPICE) is a registry of experts who provide effective tailor-made mentorship for establishing successful and long-standing Infectious Disease Management (IDM), Infection Prevention & Control (IPC) and Antimicrobial Stewardship (AMS) programmes in low resource settings.
                </Typography>
                <p>The Terms of Reference for SPICE experts can be found <a href={AppRoutes.TermsAndConditionsExpert}>here</a>.</p>
                <p>If you are interested, please register through the form below.</p>
                <p>If you agree to register, any processing of provided data will comply with EU Regulations 45/2001 and 2018/1725. As the host of the registry, Radboudumc is the main data controller of this processing operation.</p>
            </div>
            {isSuccess ? (
                <div style={{ display: 'flex', gap: '3rem', justifyContent: 'center', margin: '2.5rem auto' }}>
                    <Alert sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10rem' }} severity="success">
                        <strong><h3>Thank you for your expressed interest. Our team will contact you with more information on possible collaboration.</h3></strong>
                    </Alert>
                </div>
            ) : (
                <>
                    <Typography variant="h4" color={'primary'} m={5} textAlign={'center'}>Expert Registry</Typography>
                    <Box component="form" noValidate autoComplete="off" className="content-bea-form">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Name"
                                    id="outlined-size-small"
                                    name="name"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('name') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('name')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Surname"
                                    id="outlined-size-small"
                                    name="surname"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('surname') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('surname')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Institution (if retired, please state so)"
                                    id="outlined-size-small"
                                    name="institution"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('institution') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('institution')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Phone"
                                    id="outlined-size-small"
                                    name="phone"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('phone') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('phone')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    id="outlined-size-small"
                                    name="email"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    type="email"
                                    helperText={emailError !== '' ? emailError : emptyFormProps.includes('email') ? 'This field is required' : ''}
                                    error={!!emailError || !!emptyFormProps.includes('email')}
                                    onBlur={handleEmailBlur}
                                    onChange={e => handleInputChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="ORCID"
                                    id="outlined-size-small"
                                    name="ORCID"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    helperText={emptyFormProps.includes('ORCID') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('ORCID')}
                                    onChange={e => handleInputChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Researcher ID"
                                    id="outlined-size-small"
                                    name="researcher_id"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    helperText={emptyFormProps.includes('researcher_id') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('researcher_id')}
                                    onChange={e => handleInputChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="National Registry ID"
                                    id="outlined-size-small"
                                    name="national_registry_id"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    helperText={emptyFormProps.includes('national_registry_id') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('national_registry_id')}
                                    onChange={e => handleInputChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-select-country"
                                    name="country"
                                    select
                                    label="Country"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('country') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('country')}
                                >
                                    {countryOptions.map((option) => (
                                        <MenuItem sx={{ '& > img': { mr: 2, flexShrink: 0 } }}/*sx={{ color: "#6c94a6", '&:hover': { backgroundColor: '#6c94a6', color: '#fff' } }}*/ key={option.value} value={option.value}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={option.flag}
                                                src={option.flag}
                                                alt=""
                                            />
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-select-level"
                                    name="level_of_expertise_id"
                                    select
                                    label="Level of expertise"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    // helperText={(emptyFormProps.includes('level_of_expertise_id ') || emptyFormProps.includes('level_of_expertise_other')) ? 'This field is required' : ''}
                                    // error={!!emptyFormProps.includes('level_of_expertise_id') || emptyFormProps.includes('level_of_expertise_other')}                                >
                                    helperText={(emptyFormProps.includes('level_of_expertise_id ')) ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('level_of_expertise_id')}                                >

                                    {level.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                    <MenuItem key="other" value="Other">
                                        Other (Please specify)
                                    </MenuItem>
                                </TextField>
                                {isOtherSelected && (
                                    <TextField
                                        label="Please specify your expertise level"
                                        name="level_of_expertise_other"
                                        variant="outlined"
                                        fullWidth
                                        value=''
                                        onChange={e => handleInputChange(e)}
                                        margin="normal"
                                    // error={!!emptyFormProps.includes('level_of_expertise_id') || emptyFormProps.includes('level_of_expertise_other')}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Work experience (in years)"
                                    id="outlined-size-small"
                                    name="work_experience"
                                    // value={0}
                                    size="small"
                                    fullWidth
                                    type="number"
                                    helperText={emptyFormProps.includes('work_experience') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('work_experience')}
                                    onChange={e => handleInputChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<UploadFile />}
                                    fullWidth
                                    sx={{ textTransform: 'none', marginBottom: '2.5vh', marginTop: '2.5vh' }}
                                >
                                    Professional resume (Please upload a resume of your educational attainment and working experience)
                                    <input
                                        type="file"
                                        hidden
                                        onChange={e => handleFileChange(e, true)}
                                        ref={fileInputRef}
                                    />
                                </Button>
                                {emptyFormProps.includes('professional_resume_base64') && (
                                    <>
                                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    <strong style={{ color: '#d32f2f' }}>This field is required</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider
                                            variant="fullWidth"
                                            sx={{ backgroundColor: '#6c94a6', mb: 2, height: '1.75px' }}
                                        />
                                    </>
                                )}
                                {fileResume && (
                                    <>
                                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <strong>{fileResume.name}</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Remove file">
                                                    <IconButton onClick={() => handleFileRemove(true)} sx={{ color: '#d32f2f' }}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Divider
                                            variant="fullWidth"
                                            sx={{ backgroundColor: '#6c94a6', mb: 2, height: '1.75px' }}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<UploadFile />}
                                    fullWidth
                                    sx={{ textTransform: 'none', marginBottom: '2.5vh' }}
                                >
                                    Professional endorsement (Please upload one letter of reference)
                                    <input
                                        type="file"
                                        hidden
                                        onChange={e => handleFileChange(e, false)}
                                        ref={fileInputRef}
                                    />
                                </Button>
                                {emptyFormProps.includes('professional_endorsment_base64') && (
                                    <>
                                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    <strong style={{ color: '#d32f2f' }}>This field is required</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider
                                            variant="fullWidth"
                                            sx={{ backgroundColor: '#6c94a6', mb: 2, height: '1.75px' }}
                                        />
                                    </>
                                )}
                                {fileEndorsment && (
                                    <>
                                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <strong>{fileEndorsment.name}</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Remove file">
                                                    <IconButton onClick={() => handleFileRemove(false)} sx={{ color: '#d32f2f' }}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Divider
                                            variant="fullWidth"
                                            sx={{ backgroundColor: '#6c94a6', mb: 2, height: '1.75px' }}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    placeholder="Please provide information of your professional society memberships"
                                    label="Professional society membership"
                                    id="outlined-size-small"
                                    name="requested_support_description"
                                    multiline
                                    rows={5}
                                    maxRows={10}
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('requested_support_description') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('requested_support_description')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ marginTop: '3rem' }} gutterBottom>Specialty</Typography>
                                <Grid item xs={12} container >
                                    <Grid item>
                                        {emptyFormProps.includes('specialty_ids') && <FormHelperText><b style={{ color: '#d32f2f' }}>This field is required</b></FormHelperText>}
                                    </Grid>
                                </Grid>
                                <Table>
                                    <TableBody>
                                        {specialtyFields.sort((a, b) => a.value.localeCompare(b.value)).map((field) => (
                                            <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                                <TableCell>
                                                    <FormControlLabel control={
                                                        <Checkbox
                                                            // checked={'yes'}
                                                            onChange={() => handleSpecialtyCheckboxChange({ value: field.value, id: field.id })}
                                                        />} label="" />
                                                    {field.value}
                                                </TableCell>
                                                {(isSurgeryChecked && field.value === 'Surgery (please specify subspeciality):' &&
                                                    <TableCell>
                                                        <TextField variant="outlined" size="small" label="Please specify subspeciality" name="specialty_surgery_subspecialty" onChange={e => handleInputChange(e)} />
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                        <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                            <TableCell>Other (please specify):</TableCell>
                                            <TableCell><TextField variant="outlined" size="small" name="specialty_other" onChange={e => handleInputChange(e)} /></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ marginTop: '3rem' }} gutterBottom>
                                    Specific registry-related experience (multiple answers possible):
                                </Typography>
                                <Typography variant="body1">
                                    Please state in which areas you would be interested to provide your expertise:
                                </Typography>
                                <Grid item xs={12} container >
                                    <Grid item>
                                        {emptyFormProps.includes('registry_related_experience') && <FormHelperText><b style={{ color: '#d32f2f' }}>This field is required</b></FormHelperText>}
                                    </Grid>
                                </Grid>
                                <Table>
                                    <TableHead sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                        <TableCell>
                                            <strong>Area</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>Years of experience</strong>
                                        </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {specificRegistryRelatedExperience.map((field) => (
                                            <TableRow key={field.value} sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                                <TableCell>{field.value}</TableCell>
                                                <TableCell>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.registry_related_experience.some(x => x.value === field.value && x.years_of_experience === "5-10")}
                                                                onChange={() => handleRegistryRelatedExperienceChange(field, "5-10")}
                                                            />
                                                        }
                                                        label="5-10"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.registry_related_experience.some(x => x.value === field.value && x.years_of_experience === ">10")}
                                                                onChange={() => handleRegistryRelatedExperienceChange(field, ">10")}
                                                            />
                                                        }
                                                        label=">10"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                            <TableCell>Other (please specify):</TableCell>
                                            <TableCell>
                                                <TextField variant="outlined" size="small" name="registry_related_experience_other" onChange={e => handleInputChange(e)} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ marginTop: '3rem' }} gutterBottom>
                                    Specific regional experience (multiple answers possible):
                                </Typography>
                                <Typography variant="body1">
                                    Please state in which geographic regions/countries you have expertise:
                                </Typography>
                                <Grid item xs={12} container >
                                    <Grid item>
                                        {emptyFormProps.includes('regional_experience') && <FormHelperText><b style={{ color: '#d32f2f' }}>This field is required</b></FormHelperText>}
                                    </Grid>
                                </Grid>
                                <Table>
                                    <TableHead sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                        <TableCell>
                                            <strong>
                                                WHO Regions
                                            </strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>
                                                Years of experience
                                            </strong>
                                        </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {regionalExperience.map(field => (
                                            <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                                <TableCell>{field.value}</TableCell>
                                                <TableCell>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.regional_experience.some(x => x.value === field.value && x.years_of_experience === "5-10")}
                                                                onChange={() => handleRegionalExperienceChange(field, "5-10")}
                                                            />
                                                        }
                                                        label="5-10"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.regional_experience.some(x => x.value === field.value && x.years_of_experience === ">10")}
                                                                onChange={() => handleRegionalExperienceChange(field, ">10")}
                                                            />
                                                        }
                                                        label=">10"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="List countries in the regions(s) you selected:"
                                    id="outlined-size-small"
                                    name="regional_experience_countries"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    type="email"
                                    helperText={emptyFormProps.includes('regional_experience_countries') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('regional_experience_countries')}
                                    onChange={e => handleInputChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h5" sx={{ marginTop: '3rem' }} gutterBottom>
                                    Language proficiency (multiple answers possible):
                                </Typography>
                                <Grid item xs={12} container >
                                    <Grid item>
                                        {emptyFormProps.includes('language_proficiency') && <FormHelperText><b style={{ color: '#d32f2f' }}>This field is required</b></FormHelperText>}
                                    </Grid>
                                </Grid>
                                <Table>
                                    <TableBody>
                                        {languages.map(lang => (
                                            <TableRow key={lang.id} sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                                <TableCell>{lang.value}</TableCell>
                                                <TableCell>
                                                    <Slider
                                                        value={proficiency[lang.value]}
                                                        onChange={handleSliderChange(lang.value)}
                                                        step={1}
                                                        marks={languageMarks}
                                                        min={0}
                                                        max={4}
                                                        valueLabelDisplay="auto"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                            <TableCell>Other (please specify):</TableCell>
                                            <TableCell>
                                                <TextField name="language_proficiency_other" onChange={e => handleInputChange(e)} variant="outlined" size="small" />
                                                <Slider
                                                    value={proficiency.Other}
                                                    onChange={handleSliderChange('Other')}
                                                    step={1}
                                                    marks={languageMarks}
                                                    min={0}
                                                    max={4}
                                                    valueLabelDisplay="auto"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h5" sx={{ marginTop: '3rem' }} gutterBottom>
                                    General availability
                                </Typography>
                                <Typography variant="body1">
                                    Our roster envisages providing online and on-site support for healthcare facilities in the areas covered by SPICE. Please state below your general availability for the programme:
                                </Typography>
                                <Grid item xs={12} container >
                                    <Grid item>
                                        {emptyFormProps.includes('general_availability') && <FormHelperText><b style={{ color: '#d32f2f' }}>This field is required</b></FormHelperText>}
                                    </Grid>
                                </Grid>
                                <Table>
                                    <TableBody>
                                        <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                            <TableCell>Online only - mentoring and advice</TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={availability.onlineOnly}
                                                            onChange={handleCheckboxChange('onlineOnly')}
                                                        />
                                                    }
                                                    label="I am available"
                                                />
                                                {availability.onlineOnly && (
                                                    <TextField
                                                        label="Specify availability (e.g., 10-20 days, 3-4 months, 1 year)"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        margin="normal"
                                                        name="general_availability_time"
                                                        onChange={e => handleInputChange(e)}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                            <TableCell>Onsite only – advice and supervision</TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={availability.onsiteOnly}
                                                            onChange={handleCheckboxChange('onsiteOnly')}
                                                        />
                                                    }
                                                    label="I am available"
                                                />
                                                {availability.onsiteOnly && (
                                                    <TextField
                                                        label="Specify availability (e.g., 10-20 days, 3-4 months, 1 year)"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        margin="normal"
                                                        name="general_availability_time"
                                                        onChange={e => handleInputChange(e)}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                            <TableCell>Online and onsite – mentoring, advice, and supervision</TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={availability.onlineOnsite}
                                                            onChange={handleCheckboxChange('onlineOnsite')}
                                                        />
                                                    }
                                                    label="I am available"
                                                />
                                                {availability.onlineOnsite && (
                                                    <TextField
                                                        label="Specify availability (e.g., 10-20 days, 3-4 months, 1 year)"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        margin="normal"
                                                        name="general_availability_time"
                                                        onChange={e => handleInputChange(e)}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ borderBottom: '2px solid', borderColor: 'primary.main' }}>
                                            <TableCell>Other (please specify):</TableCell>
                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={availability.other}
                                                    name="general_availability_other"
                                                    onChange={e => handleInputChange(e)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} marginTop={'2.5rem'} container gap="10px" alignItems="center" justifyContent="space-between">
                            <Grid item xs={12} container >
                                <Grid item>
                                    {declarationOfHonourError && <FormHelperText><b style={{ color: '#d32f2f' }}>{declarationOfHonourError}</b></FormHelperText>}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item style={{ maxWidth: '80%' }}>
                                        <FormLabel component="legend">
                                            <small>
                                                <strong>
                                                    I hereby declare that the information provided is true and correct. I also understand that any willful dishonesty may render for refusal of this application or immediate removal from the registry.                                                </strong>
                                            </small>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <RadioGroup aria-label="honur" name="declaration_of_honour" value={declarationOfHonour} onChange={handleDeclarationOfHonourChange} row>
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container >
                                <Grid item>
                                    {consentError && <FormHelperText><b style={{ color: '#d32f2f' }}>{consentError}</b></FormHelperText>}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} marginBottom={'0.75rem'}>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item style={{ maxWidth: '80%' }}>
                                        <FormLabel component="legend">
                                            <small>
                                                <strong>
                                                    I hereby confirm that I understand the Terms of Reference and express interest to be considered for the SPICE Registry. I give my consent to Radboudumc to use my personal data only for the purposes of the SPICE registry as described in the Terms of Reference. I also understand that I can withdraw my data at any time, by informing the SPICE registry contact point by e-mail.
                                                </strong>
                                            </small>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <RadioGroup aria-label="consent" name="consent" value={acceptConsent} onChange={handleAcceptConsent} row >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', width: 'fit-content', alignSelf: 'flex-start' }}>
                                    <HCaptcha sitekey={HCAPTCHA_KEY} onVerify={handleCaptchaVerify} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    fullWidth
                                    sx={{ textTransform: 'none', marginBottom: '2.5vh', backgroundColor: '#009E60', '&:hover': { backgroundColor: '#009E60' } }}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {error && (
                                    <Alert variant="filled" severity="error">
                                        Error: {error}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )
            }
        </>
    )
}

export default BecomeExpertApplyComponent;
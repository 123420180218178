import { AppRoutes } from "../routes/app-routes";

export const dropdownSubItems = {
    // 'News': [],
    'Partnerships': [], 
    // [
    //     {
    //         value: 'ICARS',
    //         route: '/partnerships/ICARS'
    //     },
    //     {
    //         value: 'ISAC',
    //         route: '/partnerships/ISAC'
    //     },
    //     {
    //         value: 'ESCMID',
    //         route: '/partnerships/ESCMID'
    //     },
    //     {
    //         value: 'Others',
    //         route: '/partnerships/others'
    //     },
    //     {
    //         value: 'Get involved',
    //         route: '/partnerships/get-involved'
    //     }
    // ],

    'Become an Expert': [
        {
            value: 'What is a SPICE expert?',
            route: '/become-expert/what-is-spice-expert'
        },
        {
            value: 'Terms and conditions',
            route: '/become-expert/terms-and-conditions'
        },
        {
            value: 'Expert stories',
            route: '/#/expert-stories',
        },
        {
            value: 'Apply',
            route: '/become-expert/apply',//'https://www.aanmelder.nl/137902/subscribe'
        }
    ],

    'Become a Trainer': [
        // {
        //     value: 'What is AMS trainer?',
        //     route: '/become-trainer/what-is-ams-trainer'
        // },
        // {
        //     value: 'Terms and conditions',
        //     route: '/become-trainer/terms-and-conditions'
        // },
        // {
        //     value: 'Our trainers',
        //     route: '/become-trainer/our-trainers'
        // }, RETURN WHEN CONTENT IS PROVIDED!!!!
        {
            value: 'What is AMS trainer?',
            route: '/become-trainer/what-is-ams-trainer'
        },
        {
            value: 'Terms and conditions',
            route: '/become-trainer/terms-and-conditions'
        },
        {
            value: 'Our trainers',
            route: '/#/expert-stories'
        },
        {
            value: 'Apply',
            route: '/become-trainer/apply'
        }
    ],

    'Request support': [
        {
            value: 'Areas of support',
            route: '/request-support/areas-of-support'
        },
        {
            value: 'Types of support',
            route: '/request-support/types-of-support'
        },
        {
            value: 'Terms and conditions',
            route: '/request-support/areas-of-support',// RETURN WHEN CONTENT IS PROVIDED  '/request-support/terms-and-conditions'
        },
        {
            value: 'Apply',
            route: '/request-support/apply'
        }
    ],

    'About': [
        {
            value: 'About SPICE',
            route: '/about/about-spice'
        },
        // {
        //     value: 'Governance',
        //     route: '/about/governance'
        // },
        // {
        //     value: 'Secretariat',
        //     route: '/about/secretariat'
        // },
        {
            value: 'Where we work',
            route: AppRoutes.WhereWeWork
        },
        // {
        //     value: 'Projects',
        //     route: '/about/projects'
        // },
        // {
        //     value: 'Missions',
        //     route: '/about/missions'
        // },
        {
            value: 'Get involved',
            route: '/about/get-involved'
        },
        {
            value: 'Contact Us',
            route: '/about/contact-us'
        }
    ]
};
// export const dropdownSubItems = {
//     'News': [],
//     'What we do': ['Where we work', 'Projects', 'Missions', 'Get involved'],
//     'Partnerships': ['ICARS', 'ISAC', 'ESCMID', 'Others', 'Get involved'],
//     'Become an Expert': ['What is a SPICE expert?', 'Terms and conditions', 'Expert stories', 'Apply'],
//     'Become a Trainer': ['What is AMS trainer?', 'Terms and conditions', 'Our trainers', 'Apply'],
//     'Request support': ['Areas of support', 'Types of support', 'Terms and conditions', 'Apply'],
//     'About': ['About SPICE', 'Governance', 'Secretariat', 'Contact Us']
// };
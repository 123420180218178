import { AppRoutes } from "../../consts/routes/app-routes";
import GenericLoremIpsum from "../shared/generic-loremipsum"

const GetInvolvedComponent = () => {
    return (
        <>
            <div class="content-wse">
                <h4>Get Involved</h4>
                <p class="intro">Interested?</p>
                <p class="intro">
                    Please apply to become <a href={AppRoutes.ExpertApply} target="_blank">expert</a> or <a href={AppRoutes.TrainerApply}>trainer</a>, or contact us <a href={AppRoutes.ContactUs}>here</a> for further details.
                </p>
            </div>
        </>
    )
}

export default GetInvolvedComponent;
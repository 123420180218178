import { AppRoutes } from "../../consts/routes/app-routes";
import "./areas-of-support.css"; 

const TypesOfSupportComponent = () => {
    return (
        <>
            <div class="content-aos">
                <h4>Types of Support</h4>
                <p class="intro">
                    SPICE program provides combined expertise through:
                </p>
                <ul>
                    <li>
                        Online support, providing mentoring and advice
                    </li>
                    <li>
                        Onsite support, providing advice and supervision or
                    </li>
                    <li>
                        Combination of online and onsite support, providing mentoring, advise and supervision.
                    </li>
                </ul>
                <p class="intro">To request support please fill in the <a href={AppRoutes.RequestSupport}>request form.</a></p>
                <p class="intro">For additional questions, please <a href={AppRoutes.AboutSpice}>contact us here.</a></p>
            </div>
        </>
    )
}

export default TypesOfSupportComponent;
import React, { useEffect, useState } from 'react';
import { Checkbox, Box, TextField, MenuItem, Grid, Typography, Button, FormControl, InputLabel, Select, FormHelperText, ListItemText, Alert } from '@mui/material';
import axios from 'axios';
import './become-trainer-apply.css';
import { requiredTrainersApplyFormProps, trainersApplyFormProps } from '../../consts/form-helpers/form-consts';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { trainerApply, verifyCaptcha } from "../../services/apply-service";

const BecomeTrainerApplyComponent = () => {
    const HCAPTCHA_KEY = 'd2169502-dac0-4a8c-b8c0-18b4da968a86';
    const [countryOptions, setCountryOptions] = useState([]);
    const [emptyFormProps, setEmptyFormProps] = useState([]);
    const [formData, setFormData] = useState({ ...trainersApplyFormProps });
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedFields, setSelectedFields] = useState([]);
    const [emailError, setEmailError] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');

    const fieldsOfExpertise = [
        {
            value: 1,
            label: 'Infectious diseases management'
        },
        {
            value: 2,
            label: 'Clinical microbiology'
        },
        {
            value: 3,
            label: 'Diagnostic stewardship and laboratory services'
        },
        {
            value: 4,
            label: 'Infection prevention and control'
        },
        {
            value: 5,
            label: 'Antimicrobial Stewardship'
        }
    ];

    const handleEmailBlur = () => { setEmailError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ? 'Invalid email format' : '') };
    const handleCaptchaVerify = token => {
        setError(null);
        setCaptchaToken(token);
    };
    const handleFieldChange = (e) => {
        setError(null);
        setEmptyFormProps((props) => props.filter(prop => prop !== 'field_of_expertise_ids'));

        if (e.target.value.length <= 3) {
            setSelectedFields(e.target.value);
            setFormData({ ...formData, field_of_expertise_ids: e.target.value })
        }
    };

    useEffect(() => {
        setError(null);

        axios.get('https://restcountries.com/v3.1/all')
            .then((response) => {
                const newCountries = response.data.map(country => ({
                    label: country.name.common === 'North Macedonia' ? 'Macedonia' : country.name.common,
                    value: country.name.common === 'North Macedonia' ? 'Macedonia' : country.name.common,
                    flag: country.flags.png
                }));

                newCountries.sort((a, b) => a.label.localeCompare(b.label));

                setCountryOptions(newCountries);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleInputChange = e => {
        setError(null);
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (emptyFormProps.includes(e.target.name)) {
            setEmptyFormProps((props) => props.filter(prop => prop !== e.target.name));
        }
    };

    const validateForm = () => {
        let isFormValid = true;

        for (let prop of requiredTrainersApplyFormProps) {
            if (formData[prop] === '' || formData[prop] === null || formData[prop] === false) {
                setEmptyFormProps((prev) => [...prev, prop]);
                setError('Please fill out all required fields');
                isFormValid = false;
            }
        }

        return isFormValid;
    }

    const handleSubmit = async () => {
        // if (validateForm()) {
        //     verifyCaptcha({ token: captchaToken }).then((res) => {
        //         if (res.success) {
        //             trainerApply(formData).then((res) => { }).catch(er => console.log(er));
        //             setIsSuccess(true);
        //         } else {
        //             setError('Captcha verification failed. Please try again.');
        //         }
        //     })
        // }

        trainerApply(formData).then((res) => { }).catch(er => console.log(er));
        setIsSuccess(true);
    }

    return (
        <>
            {isSuccess ? (
                <div style={{ display: 'flex', gap: '3rem', justifyContent: 'center', margin: '20rem auto' }}>
                    <Alert sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10rem' }} severity="success">
                        <strong><h3>Thank you. Your application was successfully sent. You will be contacted for further details.</h3></strong>
                    </Alert>
                </div>) : (
                <>
                    <Typography variant="h4" color={'primary'} m={2.5} textAlign={'center'}>Trainers Registry</Typography>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className='content-bta-form'
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Name"
                                            id="name"
                                            name='name'
                                            defaultValue=""
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            helperText={emptyFormProps.includes('name') ? 'This field is required' : ''}
                                            error={!!emptyFormProps.includes('name')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Surname"
                                            id="surname"
                                            name='surname'
                                            defaultValue=""
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            helperText={emptyFormProps.includes('surname') ? 'This field is required' : ''}
                                            error={!!emptyFormProps.includes('surname')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Email"
                                            id="email"
                                            name='email'
                                            type="email"
                                            defaultValue=""
                                            size="small"
                                            fullWidth
                                            error={!!emailError || !!emptyFormProps.includes('email')}
                                            onChange={e => handleInputChange(e)}
                                            onBlur={handleEmailBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Phone number"
                                            id="outlined-size-small"
                                            name="phone_number"
                                            defaultValue=""
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            helperText={emptyFormProps.includes('phone_number') ? 'This field is required' : ''}
                                            error={!!emptyFormProps.includes('phone_number')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={!!emptyFormProps.includes('field_of_expertise_ids')}>
                                            <InputLabel id="field-of-expertise-label">Field of Expertise</InputLabel>
                                            <Select
                                                labelId="field-of-expertise-label"
                                                id="field-of-expertise"
                                                multiple
                                                value={selectedFields}
                                                onChange={handleFieldChange}
                                                renderValue={(selected) => selected.map(value => {
                                                    const option = fieldsOfExpertise.find(opt => opt.value === value);
                                                    return option ? option.label : '';
                                                }).join(', ')}
                                                size="large"
                                            >
                                                {fieldsOfExpertise.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        <Checkbox checked={selectedFields.indexOf(option.value) > -1} />
                                                        <ListItemText primary={option.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {emptyFormProps.includes('field_of_expertise_ids') && (<FormHelperText>This field is required</FormHelperText>)}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <p>I attended the drive-AMS course: (provide at least month/year)</p>
                                        <TextField
                                            label=""
                                            id="drive_ams_course_attended"
                                            name='drive_ams_course_attended'
                                            defaultValue=""
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            helperText={emptyFormProps.includes('drive_ams_course_attended') ? 'This field is required' : ''}
                                            error={!!emptyFormProps.includes('drive_ams_course_attended')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-select-country"
                                            name="country"
                                            select
                                            label="Country"
                                            defaultValue=""
                                            size="small"
                                            fullWidth
                                            onChange={e => handleInputChange(e)}
                                            helperText={emptyFormProps.includes('country') ? 'This field is required' : ''}
                                            error={!!emptyFormProps.includes('country')}
                                        >
                                            {countryOptions.map((option) => (
                                                <MenuItem sx={{ '& > img': { mr: 2, flexShrink: 0 } }}/*sx={{ color: "#6c94a6", '&:hover': { backgroundColor: '#6c94a6', color: '#fff' } }}*/ key={option.value} value={option.value}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        srcSet={option.flag}
                                                        src={option.flag}
                                                        alt=""
                                                    />
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <div style={{ display: 'flex', width: 'fit-content', alignSelf: 'flex-start' }}>
                                            <HCaptcha sitekey={HCAPTCHA_KEY} onVerify={handleCaptchaVerify} />
                                        </div>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box></>
            )}
        </>
    );
}

export default BecomeTrainerApplyComponent;
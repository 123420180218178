import './App.css';
import NavBar from './components/shared/navbar';
import { Footer } from './components/shared/footer';
import RouteProvider from './components/routes/route-provider';

function App() {
  return (
    <div className='app-wrapper' >
      <NavBar className='navbar' />
      <div className='content'>
        <RouteProvider />
      </div>
      <footer className='footer'>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
import { AppRoutes } from "../../consts/routes/app-routes";
import "./areas-of-support.css"; 

const AreasOfSupportComponent = () => {
    return (
        <>
            <div class="content-aos">
                <h4>Areas of Support</h4>
                <p class="intro">
                    The SPICE program is providing expertise in:
                </p>
                <ul>
                    <li>Infectious disease management</li>
                    <li>Microbiology and diagnostics</li>
                    <li>Infection prevention and control</li>
                    <li>Antimicrobial Stewardship</li>
                    <li>Implementation science and behavior change</li>
                </ul>
                <p class="intro">
                    For other areas of support, please <a href={AppRoutes.AboutSpice}>contact us here.</a>
                </p>
            </div>
        </>
    )
}

export default AreasOfSupportComponent;
import LandingPageCta from "../components/cta/landing-page-cta"
import FeaturedStory from "../components/info/featured-story"
import MeetOurExperts from "../components/info/meet-our-experts";
import NewsCarousel from "../components/info/featured-story-carousel"
import Divider from '@mui/material/Divider';
import { StyledEngineProvider } from '@mui/material/styles';
import './landing-page.css';
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
    const expertsRef = useRef(null);
    const location = useLocation();
    
    useEffect(() => {
        if (location.hash === '#/expert-stories' && expertsRef.current) {
            expertsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    return (
        <StyledEngineProvider injectFirst>
            <div className="container">
                <div>
                    <LandingPageCta />
                </div>
                <Divider variant="middle" sx={{ backgroundColor: 'gainsboro' }} />
                <div className="news-container">
                    <div className="news-inner-container">
                        <NewsCarousel />
                    </div>
                </div>
                <Divider variant="middle" sx={{ backgroundColor: 'gainsboro' }} />
                <br></br>
                <div ref={expertsRef}>
                    <MeetOurExperts />
                </div>
            </div>
        </StyledEngineProvider>
    )
}

export default LandingPage;
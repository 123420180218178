import { AppRoutes } from "../routes/app-routes";

export const pages = [
    // {
    //     value: 'News',
    //     route: AppRoutes.News
    // },
    {
        value: 'Partnerships',
        route: AppRoutes.Partnerships
    },
    {
        value: 'Become an Expert',
        route: ''
    },
    {
        value: 'Become a Trainer',
        route: ''
    },
    {
        value: 'Request support',
        route: ''
    },
    {
        value: 'About',
        route: ''
    }
];
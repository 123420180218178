import { AppRoutes } from "../../consts/routes/app-routes";
import './what-is-spice-expert.css';

const WhatIsSpiceExpertComponent = () => {
    return (
        <>
            <div class="content-wse">
                {/* <h1>What is SPICE Expert</h1> */}
                <p>SPICE expert can be any professional interested to share their knowledge and experience with the colleagues and peers from resource-limited settings. Becoming a SPICE expert, involves expression of interest, after what a vetting procedure takes place, which determines if you qualify to become part of the Global (or regional) SPICE roster of experts.</p>
                <p>Apart from having a distinct expertise and motivation to assist in achieving high quality infectious disease care everywhere, SPICE experts are professionals who are devoted to this mission and commit to dedicate their time and professional expertise to colleagues around the world in achieving high quality, effective and safe infectious disease care everywhere.</p>
                <h4>The SPICE experts contribute to <a href={AppRoutes.AboutSpice}>program’s scope</a> and objectives through providing:</h4>
                <ul>
                    <li>Know-how to host institution on the basis of your experience and knowledge and</li>
                    <li>Expertise and support for implementation of plans addressing the needs of the host institution with regards to the above areas of expertise.</li>
                </ul>
                <h4>The expert consultants are expected to provide their expertise and knowledge through:</h4>
                <ul>
                    <li>Online support, providing mentoring and advice</li>
                    <li>Onsite support, providing advice and supervision or</li>
                    <li>Combination of online and onsite support, providing mentoring, advice and supervision.</li>
                </ul>
            </div>
        </>
    )
}

export default WhatIsSpiceExpertComponent;
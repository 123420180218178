import { APIRoutes } from "../consts/routes/api-routes";
import API from "./base-service";

export const contactUs = async body => {
    try {
        const result = await API.post(APIRoutes.ContactUs, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (err) {
        return null;
    }
}